* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Csse */

.color-black {
    color: #282c34;
}

.outer-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f7f7f7;
    padding-bottom: 5%;
}


/* Carousel */

.carousel-container {
    width: 70vw;
    height: 350px;
}

.card-shadow {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
}

.flx {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* icons */

.icon {
    cursor: pointer;
}

.App button:focus {
    outline: none;
}

.heading-container {
    position: relative;
}

.heading-container h1 {
    color: rgba(224, 224, 224, 0.4);
    font-size: 129px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.heading-container h3 {
    color: #313131;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Libre Baskerville", serif;
    font-weight: 600;
    font-size: 45px;
}

.tag-line {
    color: #616161;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    margin-top: -30px;
}

.anchor-tag-default {
    text-decoration: none;
    color: inherit;
}

.anchor-tag-default:hover {
    text-decoration: none;
    color: inherit;
}

.anchor-tag {
    text-decoration: none;
    color: black;
}

.anchor-tag:hover {
    text-decoration: none;
    color: black;
}

.manageCookie {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 140px;
    background: #000000bf;
    color: white;
    padding: 40px 32px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.manageCookie>p {
    max-width: 80%;
    float: left;
}

.manageCookie>button {
    float: left;
    width: auto;
    padding: 8px;
    border: 1px solid #67548c;
    border-radius: 8px;
    background: #67548c;
    color: white;
    font-weight: 700;
    margin-left: 40px;
}

@media only screen and (max-width: 767px) {
    .heading-container h1 {
        letter-spacing: -30px;
    }
    .heading-container h3 {
        font-size: 31px;
        line-height: 46px;
    }
    .tag-line {
        line-height: 25px;
    }
    .manageCookie {
        flex-direction: column;
    }
}
