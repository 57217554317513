@media only screen and (max-width: 600px) {    
    div.wa {
        bottom: 84px;
    }

    .bot-list-headers {
        width: 96%;
        margin: auto;
    }
    .bot-list-body .bot-list-body-item {
        width: 88%;
    }
    .bot-center-questions {
        font-size: 16px;
        padding: 0;
        width: 88%;
    }
    .bot-questions {
        font-size: 16px;
        margin: 48px auto 24px;
        width: 92%;
    }
    .bot-center-button {
        font-size: 24px;
    }
    .bot-input-container {
        margin-top: 12%;
    }
    .bot-prev-step {
        top: 100px;
        left: 4%;
        width: 80px;
    }
    .bot-next-step {
        top: 100px;
        right: 4%;
        width: 80px;
    }
    /* input question */
    .bot-input {
        padding: 4px 16px;
        font-size: 24px;
    }
    .bot-input:focus-visible {
        margin-top: 50% !important;
        top: 0px !important;
        position: fixed !important;
    }
    /* country question */
    .bot-select {
        min-width: auto;
        font-size: 16px;
    }
    /* choice question */
    .bot-ask-choices {
        padding: 0px 8px;
    }
    .bot-ask-choice {
        font-size: 14px;
        padding: 8px 24px;
    }
    /* chatbot choices */
    .bot-choice {
        font-size: 12px;
    }
    .bot-choices-2 {
        padding-left: 44px;
    }
    .bot-video-list {
        margin-left: 32px;
    }
    .bot-chat-header {
        padding: 16px 0;
    }
    .bot-chat-new-user {
        min-width: 94px;
    }
    .bot-message {
        margin-bottom: 32px;
        padding: 12px 24px;
    }
    .bot-chat-menu>svg {
        width: 32px;
        height: 32px;
        margin-left: 8px;
    }
    .bot-chat-input>input {
        font-size: 16px;
        padding: 0px 16px;
    }
    .bot-chat-send {
        padding: 4px 16px;
        min-width: 56px;
        font-size: 16px;
        border-radius: 8px;
    }
    .hide-sm {
        display: none;
    }
    .bot-choice>div {
        min-height: 19px;
    }
    .bot-chat-interface-container {
        padding: 0 8px;
    }
    .bot-chat-bar {
        border-radius: 16px;
        max-height: 56px;
    }
    .bot-list-headers {
        margin: 12px;
        max-width: 100%;
        font-size: 16px;
        margin-bottom: 24px;
    }
    .bot-search-bar {
        margin: 24px 0 0;
        width: auto;
    }
    .bot-search-bar>label>span {
        font-size: 16px;
    }
    .info-modal-container {
        padding: 0 2%;
    }
    .info-modal-container>.modal-body>.scroll-down-container {
        bottom: 8px;
        width: 86vw;
    }
    .bot-list-headers .bot-list-headers-item {
        width: 50%;
        padding: 0 8px;
    }
    .chat-buttons-list {
        flex-wrap: wrap;
        justify-content: center;
    }
    .chat-message-button {
        margin: 4px;
        padding: 0 8px;
    }
}
