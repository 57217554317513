.sws-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 6px 30px;
  border-radius: 12px;
  background: var(--subtle, #e8e1f8);
  color: #655589;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.sws-h1 {
}
.sws-h2 {
}
.sws-h3 {
}
.sws-h4 {
}
.sws-h5 {
}
.sws-body {
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ml-2 {
  margin-left: 2px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.color-purple-300 {
  color: var(--subtle, #c3b7dc);
}
.color-purple-500 {
  color: var(--dark, #473063);
}
